"use strict";
/*
  Autogenerated, do not edit.
  All changes will be undone.
  types version: 0.0.0-snapshot-f046c196d075cfe36fff00f50a07eed95001b756-2024-10-08
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.FleetConsumptionForecast = void 0;
class FleetConsumptionForecast {
    constructor(values) {
        Object.assign(this, values);
    }
}
exports.FleetConsumptionForecast = FleetConsumptionForecast;
